import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function MerchantSuccessLead () {
  return (
    <CareerLayout title='Merchant success lead' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a Merchant Success Lead, you’ll champion merchants’ needs to the rest of the team. Building relations is important to you and are proficient at communicating between multiple parties.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Educating merchants on platform use and new features as they become available</li>
        <li>Owning and improving the onboarding process</li>
        <li>Clarifying merchant delivery needs to set them up with strategies for success</li>
        <li>Setting clear expectations</li>
        <li>Recommending helpful features/ strategies to merchants based on changing needs</li>
        <li>Responding in a timely manner to merchant queries</li>
        <li>Proactive communication with merchants to mitigate foreseeable issues</li>
        <li>Collaborate and troubleshoot on important issues for Merchants</li>
        <li>Build relationships with Merchant partners by being main contact and expert for our highest revenue Merchants for their support operations</li>
        <li>In general, being a positive, efficient point of contact so that even when things go wrong, merchants feel supported</li>
        <li>Promote retention and overall Merchant success through service.</li>
        <li>Helping to identify gaps/ issues for the team to solve that would improve the merchant experience</li>
        <li>Prioritize and escalate issues in partnership with our teams</li>
        <li>Communicate, validate and track Merchant feedback to inform updates to our products and tools</li>
        <li>Learn the Trexity platform and be able to find ways to create more value for our merchants</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>2 years of experience with account management, customer support, hospitality or in related field</li>
        <li>Previous industry experience</li>
        <li>Worked with a CRM tool (we use Hubspot)</li>
        <li>Proficiency in excel (can perform complex functions)</li>
        <li>Experience providing advisory and partnership-level support through both proactive and reactive interactions with customers</li>
        <li>Experience advocating for customer experience within a team or initiative</li>
        <li>You have experience in an account management or customer service role</li>
        <li>You are a proven relationship builder</li>
        <li>Are able to prioritize multiple competing priorities</li>
        <li>You are a creative thinker to solve problems in real time.</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
